/* Style date range picker */
#root {
  height: 100vh;
}
.MuiPickersArrowSwitcher-iconButton svg {
  font-size: 3rem;
  color: #3c3c3b;
}
.MuiPickersDesktopDateRangeCalendar-arrowSwitcher h6 {
  font-size: 1.6rem;
  color: #3c3c3b;
  font-weight: 600;
}
.MuiPickersCalendar-weekDayLabel.MuiTypography-caption {
  font-size: 1.2rem;
  color: #acacab;
}

.MuiPickersDateRangeDay-day > * {
  transform: scale(1.5) !important;
}

.MuiPickerCustom-roundCorners > .MuiPaper-root {
  border-radius: 13px;
}

.MuiPickersDay-dayLabel {
  font-size: 1.5rem;
}

.MuiTypography-subtitle1 {
  font-size: 1.5rem !important;
}

.MuiPickersYear-yearButton {
  font-size: 1.5rem !important;
}

.MuiOutlinedInput-input {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}
