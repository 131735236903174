.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: 418px;
  max-width: 594px;
  object-fit: contain;
  padding: 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 200px;
    padding: 0;
  }
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 69px;
  align-items: center;
  object-fit: cover;
  cursor: pointer;
  margin: 0;
  padding: 1px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: 4px solid #ed6c5d;
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 15px 0px 0px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 80px;
  width: 43px;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  top: 113%;
  padding: 10px;
}

.image-gallery-icon {
  color: #fff;
  transition: all 0.2s ease-out;
  filter: none;
}

.image-gallery-icon:hover {
  color: #ed6c5d;
  filter: none;
}

.image-gallery-icon:hover .image-gallery-svg {
  transform: scale(1.05);
}

.image-gallery-left-nav {
  left: -55px;
  color: #a4a4a4;
}

.image-gallery-right-nav {
  right: -55px;
  color: #a4a4a4;
}

@media only screen and (max-width: 960px) {
  .image-gallery-left-nav {
    display: none;
  }
  .image-gallery-right-nav {
    display: none;
  }
}
